import {http} from "Services/Http/HttpClient";
import {IdName} from "Model/Shared/IdName";
import FilterBuilder from "Services/Filters/FilterBuilder";

export interface ActivityListResponse {
    center: IdName,
    endDate: string,
    id: string,
    image: string|null,
    name: string,
    startDate: string
}

export const getAllActivities = async(filters: {[key:string]:string|number} = {}) : Promise<any> => {
    const params = new URLSearchParams();

    for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
            params.append(key, filters[key].toString());
        }
    }

    const paramsString = params.toString().length ? '?' + params.toString() : ''

    return await http.get('/activities' + paramsString)
}

export const getActivities = async() : Promise<ActivityListResponse[]> => {
    return await http.get(`/available-activities`)
}

export const searchAdminActivities = async(filters: any = {}) : Promise<any[]> => {
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    return await http.get(`activities/admin` + (queryParams ? '?' + queryParams : ''))
}

export const getActivity = async(
    activityId: string
) : Promise<unknown> => {
    return await http.get(`/activities/${activityId}`)
}

export const getActivitySessions = async(filters: any = {}) : Promise<any> => {
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    return await http.get('/activities/sessions' + (queryParams ? '?' + queryParams : ''))
}

export const getActivitySession = async(id: string) : Promise<any> => {
    return await http.get(`/activities/session/${id}`)
}

export const getActivitySessionByChild = async(
    activityId: string,
    childId: string
) : Promise<any> => {
    return await http.get(`/activity/${activityId}/kid/${childId}`)
}