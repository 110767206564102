import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import DateService from "Services/Date/DateService";
import {MoneyAmount} from "Components/Money/MoneyAmount";
import {AddYearDayModalForm} from "Views/Modalities/AddYearDayModalForm";
import {Notificator} from "Services/Notificator/Notificator";

interface Props {
    yearDays?: any,
    services?: any,
    onChange: (yearDays: any) => void
}

export const AdminYearDayForm: React.FC<Props> = (props) => {

    const [showAddYearDayModalForm, setShowAddYearDayModalForm] = useState<boolean>(false)
    const [yearDays, setYearDays] = useState<any[]>([])

    const addYearDay = (data: any) => {
        const newYearDays = [...yearDays, data]
        props.onChange(newYearDays)
        setYearDays(newYearDays)
        setShowAddYearDayModalForm(false)
        Notificator.success('El día se ha añadido correctamente')
    }

    const deleteYearDay = (index: number) => {
        const newYearDays = yearDays.filter((item: any, key: number) => key !== index)
        props.onChange(newYearDays)
        setYearDays(newYearDays)
        Notificator.success('El día se ha eliminado correctamente')
    }

    useEffect(() => {
        setYearDays(props.yearDays)
    }, [props.yearDays])

    return (
        <>
            <h4>Días</h4>
            <span>Días del año</span>

            {
                yearDays?.map((yearDay: any, key: number) => (
                    <div key={key} className={"d-block mb-3 mt-3"}>
                        <Card>
                            <Card.Body>
                                <strong>Fecha:</strong> {DateService.convertYYYYMMDDFormatToDDMMYYYY(yearDay.date)}
                                {yearDay.name && <span
                                    className={"d-block"}><strong>Nombre:</strong> {yearDay.name}</span>}
                                {yearDay.description && <span
                                    className={"d-block"}><strong>Descripción:</strong> {yearDay.description}</span>}
                                {yearDay.price > 0 && <span
                                    className={"d-block"}><strong>Precio:</strong> <MoneyAmount
                                    amount={yearDay.price}/></span>}
                                {yearDay.billable && <span
                                    className={"d-block"}><strong>Facturable:</strong> {yearDay.billable ? 'Sí' : 'No'}</span>}
                                {yearDay.start && <span
                                    className={"d-block"}><strong>Inicio:</strong> {yearDay.start}</span>}
                                {yearDay.end &&
                                    <span className={"d-block"}><strong>Fin:</strong> {yearDay.end}</span>}

                                <Button
                                    onClick={() => deleteYearDay(key)}
                                    variant={"danger"}
                                    className={"d-block mt-2"}
                                    size={"sm"}
                                >Eliminar</Button>
                            </Card.Body>
                        </Card>
                    </div>
                ))
            }

            <Button
                className={"btn-dark btn-sm d-block mt-2"}
                onClick={() => setShowAddYearDayModalForm(true)}
            >
                Añadir día
            </Button>

            <AddYearDayModalForm
                show={showAddYearDayModalForm}
                onClose={() => setShowAddYearDayModalForm(false)}
                onSubmit={(data: any) => addYearDay(data)}
            />
        </>
    )
}
