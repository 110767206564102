import React from "react";
import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";

interface Props{
    children: React.ReactNode,
    classes?: string
}

export const DefaultContainer: React.FC<Props> = (props) => {
    return (
        <Container className={"body-container " + (props.classes ? props.classes : '')}>
            <Row>
                {props.children}
            </Row>
        </Container>
    )
}
