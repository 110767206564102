export default class DateService {

    static addLeftZero = (value: number) : string => {
        let formattedValue : string = value.toString()

        if (value < 10) {
            formattedValue = '0' + formattedValue
        }

        return formattedValue
    }

    static convertDateToYYYYMMDD = (date : Date) : string => {
        let month = date.getMonth() + 1
        const finalMonth = DateService.addLeftZero(month)

        let day = date.getDate()
        const finalDay = DateService.addLeftZero(day)
        return date.getFullYear() + '-' + finalMonth + '-' + finalDay
    }

    static convertYYYYMMDDFormatToDDMMYYYY = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    }
}
