import {DropzoneFile} from "Components/FileUpload/FileUpload";
import axios from "axios";
import {getAuth} from "Services/Security/AuthService";
import {http} from "Services/Http/HttpClient";

export const uploadActivityMedia = async (
    activityId: string,
    file: DropzoneFile,
    tag: string = ''
) => {
    let data = new FormData()
    data.append('file', file.file)

    if (tag) {
        data.append('tag', tag)
    }

    const fhttp = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-type": "multipart/form-data;",
            "Authorization": getAuth()
        }
    })

    return fhttp.post(`activity/${activityId}/medias`, data)
}

export const updateActivity = async (
    activityId: string,
    centerIds: string[],
    name: string,
    startDate: string,
    endDate: string,
    initPaymentDate: string,
    endPaymentDate: string,
    description: string,
    discounts: string[],
    services: string[],
    yearDays: any[],
) => {
    return http.patch(`activities/${activityId}`, {
        centers: centerIds,
        name: name,
        startDate: startDate,
        endDate: endDate,
        initPaymentDate: initPaymentDate,
        endPaymentDate: endPaymentDate,
        description: description,
        discounts: discounts,
        services: services,
        yearDays: yearDays
    })
}

export const deleteActivities = async(ids: string[]) : Promise<any> => {
    return http.delete('/activities/delete', {
        data: {
            ids: ids
        }
    })
}

