import Toggle from 'react-toggle'
import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {FileUpload} from "Components/FileUpload/FileUpload";
import {
    ACTIVITY_TYPE_LABEL_BY_IDS, ACTIVITY_TYPE_WEEKLY_ID,
    ACTIVITY_TYPE_YEARLY_ID,
    ActivityTypeSelect
} from "Components/Activities/ActivityTypeSelect";
import {AdminYearDayForm} from "Views/Activities/AdminYearDayForm";
import {useQuery} from "react-query";
import {getMutipleCenterServices} from "Api/Query/CenterQuery";
import {Notificator} from "Services/Notificator/Notificator";
import {useDispatch} from "react-redux";
import {appLoading} from "Store/loadingSlice";
import {Time} from "Components/Clock/Time";
import {MoneyAmount} from "Components/Money/MoneyAmount";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {SelectOptionType} from "Model/Select/SelectOption";
import {SimpleHtmlTextEditor} from "Components/TinyMce/SimpleHtmlTextEditor";
import {CenterMultiSelect} from "Components/Center/CenterMultiSelect";
import {DiscountMultiSelect} from "Components/Discount/DiscountMultiSelect";

interface Props {
    onSubmit: (data: any, isValid: boolean) => void,
    defaultValues?: { [key: string]: any }
    isEdit?: boolean
}

export const AdminActivityForm: React.FC<Props> = (props) => {

    const defaultFormData = {
        name: "",
        type: "",
        initDate: "",
        endDate: "",
        initPaymentDate: "",
        endPaymentDate: "",
        description: "",
        frontImage: "",
        backgroundImage: "",
        yearDays: [],
        services: [],
        sessions: [],
        centerIds: [],
        centers: [],
        discountIds: [],
        discounts: []
    }
    const [formData, setFormData] = useState<{ [key: string]: any }>(defaultFormData)
    const [centerServices, setCenterServices] = useState<any[]>([])
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: boolean }>({})
    const [submitted, setSubmitted] = useState<boolean>(false)

    const centerServicesQuery = useQuery({
        enabled: false,
        queryKey: ['adminCenterServicesQuery', formData.centerIds],
        queryFn: () => getMutipleCenterServices(formData.centerIds), // TODO: refactor
        onSuccess: (response: any) => {
            setCenterServices(response.data._result.items)
        }
    })

    const addSelectedServices = (serviceId: string): void => {
        const newSelectedServices = [...formData.services, serviceId]
        setFormData({...formData, services: newSelectedServices})
    }

    const removeSelectedService = (serviceId: string): void => {
        const services: any = formData.services.filter((itemId: string) => itemId !== serviceId)
        setFormData({...formData, services: services})
    }

    useEffect(() => {
        if (formData.centerIds.length) {
            centerServicesQuery.refetch()
        } else {
            setCenterServices([])
        }
    }, [formData.centerIds])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(appLoading({value: centerServicesQuery.isLoading}))
    }, [centerServicesQuery.isLoading])

    useEffect(() => {
        if (!submitted) {
            return
        }

        validate(false)
    }, [formData])

    const submit = () => {
        if (!submitted) {
            setSubmitted(true)
        }

        const isValidForm = validate()
        props.onSubmit(formData, isValidForm)
    }

    useEffect(() => {
        let newFormData: any = props.defaultValues ?? defaultFormData

        // centers
        if (props.defaultValues?.centers) {
            const centerOptions: SelectOptionType[] = []
            props.defaultValues?.centers.map((center: any) => {
                centerOptions.push({
                    label: center.name,
                    value: center.id
                })
            })
            newFormData = {...newFormData, centers: centerOptions}
            newFormData = {...newFormData, centerIds: props.defaultValues?.centerIds}
        }

        // discounts
        if (props.defaultValues?.discounts) {
            const options: SelectOptionType[] = []
            props.defaultValues?.discounts.map((item: any) => {
                options.push({
                    label: item.name,
                    value: item.id
                })
            })
            newFormData = {...newFormData, discounts: options}
            newFormData = {...newFormData, discountIds: props.defaultValues?.discountIds}
        }

        setFormData(newFormData)

    }, [props.defaultValues])

    const validate = (showAlert = true): boolean => {
        const errors: { [key: string]: boolean } = {}

        const requiredFields: string[] = [
            'name', 'type', 'centerIds', 'initDate', 'endDate', 'initPaymentDate', 'endPaymentDate', 'frontImage'
        ]

        requiredFields.map(field => {
            if (!formData[field]) {
                errors[field] = true
            }
        })

        setValidationErrors(errors)

        if (Object.keys(errors).length === 0) {
            return true
        }

        if (showAlert) {
            Notificator.error('Los campos en rojo son obligatorios.', 'Error')
        }

        return false
    }

    const isServiceChecked = (service: any) => {
        if (!props.defaultValues?.services) {
            return false
        }

        const exists = props.defaultValues.services.filter(defaultService => defaultService.id === service.id)

        if (exists.length > 0) {
            return true
        }

        return false
    }

    return (
        <>

            {/*{JSON.stringify(formData)}*/}

            <Col id={"mainRequestList"}>

                <Row>
                    <Col xs={12} sm={6} md={6} lg={6} className={"mb-4"}>
                        <Form.Group controlId={"name"}>
                            <Form.Label>
                                Nombre
                                &nbsp;<FormLabelRequiredAsterisk/>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                    ...formData,
                                    name: e.target.value
                                })}
                                required
                                value={formData.name}
                                isInvalid={!!validationErrors.name}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6} className={"mb-4"}>
                        <Form.Group controlId={"type"}>
                            <Form.Label>
                                Tipo
                                &nbsp;<FormLabelRequiredAsterisk/>
                            </Form.Label>
                            {props.isEdit && <h5>{ACTIVITY_TYPE_LABEL_BY_IDS[formData.type]}</h5>}

                            {!props.isEdit &&
                                <ActivityTypeSelect
                                    onChange={(option: any) => setFormData({...formData, type: option?.value ?? ""})}
                                    value={formData.type}
                                    classes={!!validationErrors.type ? 'is-invalid' : ''}
                                />
                            }
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6} className={"mb-4"}>
                        <Form.Label>
                            Centro/s
                            &nbsp;<FormLabelRequiredAsterisk/>
                        </Form.Label>
                        <CenterMultiSelect
                            onChange={(options: any) => {
                                let optionValues: string[] = []
                                options.map(opt => optionValues.push(opt.value))

                                let newFormData = formData
                                newFormData = {...newFormData, centerIds: optionValues ?? []}
                                newFormData = {...newFormData, centers: options}
                                setFormData(newFormData)
                            }}
                            values={formData.centers}
                            classes={!!validationErrors.centerIds ? 'is-invalid' : ''}
                        />
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6} className={"mb-4 align-self-end"}>
                        <Form.Label>
                            Descuento/s
                        </Form.Label>
                        <DiscountMultiSelect
                            onChange={(options: any) => {
                                let optionValues: string[] = []
                                options.map(opt => optionValues.push(opt.value))

                                let newFormData = formData
                                newFormData = {...newFormData, discountIds: optionValues ?? []}
                                newFormData = {...newFormData, discounts: options}
                                setFormData(newFormData)
                            }}
                            values={formData.discounts}
                        />
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6} className={"mb-4"}>
                        <Form.Group controlId={"startDate"}>
                            <Form.Label>
                                Fecha de inicio
                                &nbsp;<FormLabelRequiredAsterisk/>
                            </Form.Label>
                            <Form.Control
                                type="date"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                    ...formData,
                                    initDate: e.target.value
                                })}
                                required
                                value={formData.initDate}
                                isInvalid={!!validationErrors.initDate}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6} className={"mb-4"}>
                        <Form.Group controlId={"endDate"}>
                            <Form.Label>
                                Fecha fin
                                &nbsp;<FormLabelRequiredAsterisk/>
                            </Form.Label>
                            <Form.Control
                                type="date"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                    ...formData,
                                    endDate: e.target.value
                                })}
                                required
                                value={formData.endDate}
                                isInvalid={!!validationErrors.endDate}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6} className={"mb-4"}>
                        <Form.Group controlId={"startDate"}>
                            <Form.Label>
                                Fecha de pago inicial
                                &nbsp;<FormLabelRequiredAsterisk/>
                            </Form.Label>
                            <Form.Control
                                type="date"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                    ...formData,
                                    initPaymentDate: e.target.value
                                })}
                                required
                                value={formData.initPaymentDate}
                                isInvalid={!!validationErrors.initPaymentDate}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6} className={"mb-4"}>
                        <Form.Group controlId={"endDate"}>
                            <Form.Label>
                                Fecha de pago final
                                &nbsp;<FormLabelRequiredAsterisk/>
                            </Form.Label>
                            <Form.Control
                                type="date"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                    ...formData,
                                    endPaymentDate: e.target.value
                                })}
                                required
                                value={formData.endPaymentDate}
                                isInvalid={!!validationErrors.endPaymentDate}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>

                        <Form.Group controlId={"description"}>
                            <Form.Label>
                                Descripción
                                &nbsp;<FormLabelRequiredAsterisk/>
                            </Form.Label>

                            <SimpleHtmlTextEditor
                                onChange={(htmlContent) => {
                                    setFormData({...formData, description: htmlContent})
                                }}
                                defaultContent={formData.description}
                            />
                        </Form.Group>

                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group controlId={"frontImage"}>
                            <Form.Label>
                                Imagen de portada
                                &nbsp;<FormLabelRequiredAsterisk/>
                            </Form.Label>
                            <div className={!!validationErrors.frontImage ? 'is-invalid-div' : ''}>
                                {
                                    props.defaultValues?.frontImageSrc &&
                                    <Row className={"mb-4"}>
                                        <img src={props.defaultValues?.frontImageSrc} height={250}
                                             style={{"maxWidth": '100%'}}/>
                                    </Row>
                                }

                                <FileUpload
                                    onChange={(files) => files ? setFormData({
                                        ...formData,
                                        frontImage: files[0]
                                    }) : null}
                                    maxFiles={1}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group controlId={"backgroundImage"}>
                            <Form.Label>
                                Imagen de fondo
                            </Form.Label>
                            {
                                props.defaultValues?.backgroundImageSrc &&
                                <Row className={"mb-4"}>
                                    <img src={props.defaultValues?.backgroundImageSrc} height={250}
                                         style={{"maxWidth": '100%'}}/>
                                </Row>
                            }
                            <FileUpload
                                onChange={(files) => files ? setFormData({
                                    ...formData,
                                    backgroundImage: files[0]
                                }) : null}
                                maxFiles={1}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {
                    formData.type === ACTIVITY_TYPE_YEARLY_ID &&
                    <>
                        <Row className={"mt-4"}>
                            <Col xs={12} sm={6}>
                                <Form.Group controlId={"services"}>

                                    <h4>Servicios</h4>
                                    <p>Selecciona un centro para mostrar los servicios disponibles.</p>

                                    {
                                        centerServices.map((service: any, key: number) => {
                                            return <Col key={key}>
                                                <label
                                                    htmlFor={service.id}
                                                    className={"d-block mb-2 pointer"}
                                                >
                                                    <span className={"fw-bold"}>{service.name} +{service.price} €</span><br/>
                                                    <small>{service.center.name}</small>
                                                </label>
                                                <Toggle
                                                    id={service.id}
                                                    defaultChecked={isServiceChecked(service)}
                                                    onChange={(e) => e.target.checked === true
                                                        ? addSelectedServices(service.id)
                                                        : removeSelectedService(service.id)
                                                    }
                                                />
                                            </Col>
                                        })
                                    }
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6}>
                                <AdminYearDayForm
                                    onChange={(yearDays: any) => setFormData({...formData, yearDays: yearDays})}
                                    yearDays={formData.yearDays}
                                />
                            </Col>
                        </Row>
                    </>
                }

                {
                    formData.type === ACTIVITY_TYPE_WEEKLY_ID && props.isEdit &&
                    <>
                        <br/>
                        <Row className={"mt-4"}>
                            <Col xs={12}>
                                <Form.Group controlId={"sessions"}>
                                    <Row className={"d-flex"}>
                                        <Col sm={12} md={6}>
                                            <h3>Modalidades</h3>
                                        </Col>
                                        <Col sm={12} md={6} style={{"textAlign": "right"}}>
                                            <Button
                                                className={"purple-btn btn-sm float-right"}
                                                href={ROUTE_PATHS.ADMIN_MODALITY_CREATE}
                                            >
                                                Crear nueva modalidad
                                            </Button>
                                        </Col>
                                    </Row>

                                    <table className={"table table-bordered redTable"}>
                                        <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Días</th>
                                            <th>Horario</th>
                                            <th>Precio</th>
                                            <th></th>
                                        </tr>

                                        {
                                            formData.sessions.length === 0 &&
                                            <tr>
                                                <td colSpan={5}>
                                                    Todavía no has creado ninguna modalidad para esta actividad.
                                                </td>
                                            </tr>
                                        }
                                        {
                                            formData.sessions.map((activitySession: any, key: number) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{activitySession.name}</td>
                                                        <td>
                                                            {activitySession.days.map((day: any, key: number) => {
                                                                return (
                                                                    <span key={key}>
                                                                        {day.name}
                                                                        {key < activitySession.days.length - 1 && ', '}
                                                                    </span>
                                                                )
                                                            })}
                                                        </td>
                                                        <td>
                                                            <Time value={activitySession.start}/> - <Time
                                                            value={activitySession.end}/>
                                                        </td>
                                                        <td>
                                                            <MoneyAmount amount={activitySession.price}/>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                className={"purple-btn btn-sm"}
                                                                href={ROUTE_PATHS.ADMIN_MODALITY_EDIT.replace(":id", activitySession.id)}
                                                            >
                                                                Modificar
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                        </thead>
                                    </table>

                                </Form.Group>
                            </Col>
                        </Row>
                        <br/>
                    </>
                }

            </Col>

            <Row>
                <Col className={"mt-4"}>
                    <br/>
                    <Button
                        className={"purple-btn btn-sm"}
                        onClick={() => submit()}
                    >
                        Enviar
                    </Button>
                </Col>
            </Row>
        </>
    )
}
