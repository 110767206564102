import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {RequireAuth} from "Components/Security/RequireAuth"
import {DefaultContainer} from "Views/Shared/DefaultContainer"
import {appLoading} from "Store/loadingSlice";
import {useAppDispatch} from "hooks";
import {getActivity} from "Api/Query/ActivityQuery";
import {useQuery} from "react-query";
import {Notificator} from "Services/Notificator/Notificator";
import {ErrorMessageBuilder} from "Services/Notificator/ErrorMessageBuilder";
import {Activity} from "Model/Activity/Activity";
import {NotFound} from "Views/Router/NotFound";
import {ActivityWeeklyDetail} from "Views/Activities/ActivityWeeklyDetail";
import {ActivityYearlyDetail} from "Views/Activities/ActivityYearlyDetail";

export const ActivityDetail: React.FC = () => {

    const {id} = useParams()
    const dispatch = useAppDispatch()

    const [found, setFound] = useState<boolean>(true)
    const [activity, setActivity] = useState<Activity>({
        name: '',
        centers: [],
        description: '',
        endDate: '',
        hasDiscounts: false,
        id: '',
        image: null,
        backgroundImage: null,
        sessions: [],
        startDate: '',
        initPaymentDate: '',
        endPaymentDate: '',
        type: 0
    })

    const activityDetailQuery = useQuery(
        {
            queryKey: ['getActivity', id],
            queryFn: () => getActivity(id ?? ''),
            onSuccess: (response: any) => {
                setActivity(response.data._result)
                dispatch(appLoading({value: false}))
            },
            onError: (error: any) => {
                const notificationMessage = ErrorMessageBuilder.create(error)
                Notificator.error(notificationMessage, 'Error')
                dispatch(appLoading({value: false}))
                setFound(false)
            }
        }
    )

    useEffect(() => {
        dispatch(
            appLoading({
                value: activityDetailQuery.isLoading,
                text: 'Cargando'
            })
        )
    }, [])

    return (
        <RequireAuth>

            {!found && <NotFound/>}

            {!activity.name && <DefaultContainer>&nbsp;</DefaultContainer>}

            {
                activity.name && activity.sessions?.length > 0 &&
                <ActivityWeeklyDetail activity={activity} />
            }
            {
                activity.name && activity.type === 2 &&
                <ActivityYearlyDetail activity={activity} />
            }

        </RequireAuth>
    )
}
