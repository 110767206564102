import React, {useEffect, useState} from "react";
import Select, {MultiValue} from "react-select";
import {SelectOption, SelectOptionType} from "Model/Select/SelectOption";
import {ActivityYearDay} from "Model/Activity/ActivityYearDay";
import {SelectOptionCollection} from "Model/Select/SelectOptionCollection";

interface Props {
    onChange: (newValue: SelectOption|any) => void,
    // onChange: (newValue: MultiValue<SelectOptionType>|null) => void,
    value?: any,
    classes?: string,
    activityYearDays: ActivityYearDay[]
}

export const buildActivityYearlyDayOptionName = (value: any, addPrice: boolean = true) : string => {
    let name = ''

    if(value.name){
        name += value.name
    }

    if(value.name && value.description){
        name += ' - '
    }

    if(value.description){
        name += value.description
    }

    if(!name.length){
        const formattedDate = value.date.substring(8,10) + '/' + value.date.substring(5,7) + '/' + value.date.substring(0,4)
        name = formattedDate
    }

    if(addPrice){
        name += ` - Precio: ${value.price} €`
    }

    return name
}

export const SelectActivityYearlyDay : React.FC<Props> = (props) => {

    const [options, setOptions] = useState<any>([])

    useEffect(() => {
        if(!props.activityYearDays) return

        const collection = new SelectOptionCollection()
        for (const [key, value] of Object.entries(props.activityYearDays)) {
            const name = buildActivityYearlyDayOptionName(value)
            const option = SelectOption.fromPrimitives(name, value.id)
            collection.addPrimitive(option)
        }

        setOptions(collection.toPrimitiveOptions())
    }, [])

    return (
        <Select
            // isLoading={isLoading}
            // isMulti
            options={options}
            placeholder={""}
            noOptionsMessage={() => "No hay resultados"}
            isClearable={true}
            loadingMessage={() => "Cargando ..."}
            onChange={props.onChange}
            className={props.classes ?? ''}
            isDisabled={!props.activityYearDays}
            value={props.value}
        />
    )

}
