import {http} from "Services/Http/HttpClient";
import FilterBuilder from "Services/Filters/FilterBuilder";

export const getCenters = async (filters: any = {}): Promise<unknown> => {
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    return await http.get('centers' + (queryParams ? '?' + queryParams : ''))
}

export const getCenterDetail = async (id: string): Promise<unknown> => {
    return await http.get('centers/' + id)
}

export const getMutipleCenterServices = async (centerIds: string[], size: number = 12): Promise<any> => {
    const centersQueryParam = centerIds.join('.')
    return await http.get(`/centers-services?size=${size}&centers=` + centersQueryParam)
}
