import React from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {Button, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {getTutorChildren} from "Api/Query/UserQuery";
import {deleteChildren} from "Api/Mutation/ChildMutation";
import {AdminCrudList} from "../Shared/Crud/AdminCrudList";
import {UserService} from "Services/User/UserService";
import {TutorHasNotEnteredPersonalInfo} from "Components/Tutor/TutorHasNotEnteredPersonalInfo";
import {DefaultContainer} from "../Shared/DefaultContainer";

export const KidsList: React.FC = () => {

    const headers: string[] = [
        'NOMBRE NIÑO/A',
        'DNI/NIE',
        'CENTRO SELECCIONADO',
        'ACCIONES'
    ]

    const userService = new UserService()
    const tutorId = userService.getTutor()
    const title = 'Listado de Niños/as'

    return (
        <RequireAuth>
            <DefaultContainer>
                <Col id={"kidsList"}>

                    {!tutorId &&
                        <>
                            <h1>{title}</h1>
                            <TutorHasNotEnteredPersonalInfo showAlert={true} showButton={true}/>
                        </>
                    }

                    {tutorId &&
                        <AdminCrudList
                            title={title}
                            headers={headers}
                            queryMethod={getTutorChildren}
                            queryName={"listTutorChildren"}
                            deleteMethod={deleteChildren}
                            notFoundMessage={"No hay ningún hij@ registrado."}
                            tableRow={(item: any, key: number) =>
                                <>
                                    <td>
                                        <Link to={ROUTE_PATHS.KIDS_EDIT.replace(":id", item.id)}>
                                            {item.name} {item.surnames}
                                        </Link>
                                    </td>
                                    <td>{item.nif}</td>
                                    <td>{item.center}</td>
                                    <td className={"text-center align-middle"}>
                                        <Button
                                            className={"purple-btn btn-sm"}
                                            href={ROUTE_PATHS.KIDS_EDIT.replace(":id", item.id)}
                                        >
                                            Modificar
                                        </Button>
                                    </td>
                                </>
                            }
                            createButton={
                                <Button
                                    className={"purple-btn"}
                                    href={ROUTE_PATHS.KIDS_ADD}
                                >
                                    Nuevo hijo/a
                                </Button>
                            }
                        />
                    }

                </Col>
            </DefaultContainer>
        </RequireAuth>
    )
}
