import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {Notificator} from "Services/Notificator/Notificator";

interface Props {
    show: boolean,
    onClose: () => void,
    onSubmit: (data: any) => void
}

export const AddYearDayModalForm: React.FC<Props> = (props) => {

    const [show, setShow] = useState(false)
    const [date, setDate] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [price, setPrice] = useState<number>(0)
    const [start, setStart] = useState<string>('')
    const [end, setEnd] = useState<string>('')
    const [billable, setBillable] = useState<boolean>(false)

    const handleSubmit = () => {
        if(!date){
            Notificator.error('La fecha es obligatoria', 'Error')
            return
        }


        setShow(false)
        props.onSubmit({
            date: date,
            name: name,
            description: description,
            price: price,
            start: start,
            end: end,
            billable: billable
        })
        resetValues()
    }

    const resetValues = () => {
        setDate('')
        setName('')
        setDescription('')
        setPrice(0)
        setStart('')
        setEnd('')
        setBillable(false)
    }

    const handleClose = () => {
        setShow(false)
        props.onClose()
        resetValues()
    }
    const handleShow = () => setShow(true);

    useEffect(() => {
        setShow(props.show)
    }, [props.show])

    return (
        <Modal show={show} onHide={handleClose} size={"lg"}>
            <Modal.Header closeButton>
                <Modal.Title>Añadir día del año</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <Col xs={12} sm={6} md={6} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <label>
                                Fecha&nbsp;<FormLabelRequiredAsterisk />
                            </label>
                            <input
                                type={"date"}
                                className={"form-control"}
                                onChange={(e:any) => setDate(e.target.value)}
                                value={date}
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <label>
                                Nombre
                            </label>
                            <input
                                type={"text"}
                                className={"form-control"}
                                onChange={(e:any) => setName(e.target.value)}
                                value={name}
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <label>
                                Precio
                            </label>
                            <input
                                type={"number"}
                                className={"form-control"}
                                onChange={(e:any) => setPrice(e.target.value)}
                                value={price}
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className={"mx-auto py-2 pb-4 d-flex align-items-end"}>
                        <div className={"form-group"}>
                            <label>
                                <input
                                    type={"checkbox"}
                                    onChange={(e:any) => setBillable(e.target.checked)}
                                    checked={billable}
                                />
                                &nbsp;&nbsp;Facturable
                            </label>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <label>Hora inicio</label>
                            <input
                                type={"time"}
                                className={"form-control"}
                                onChange={(e:any) => setStart(e.target.value)}
                                value={start}
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <label>Hora fin</label>
                            <input
                                type={"time"}
                                className={"form-control"}
                                onChange={(e:any) => setEnd(e.target.value)}
                                value={end}
                            />
                        </div>
                    </Col>
                    <Col xs={12} className={"mx-auto py-2 pb-4"}>
                        <div className={"form-group"}>
                            <label>Descripción</label>
                            <textarea
                                className={"form-control"}
                                rows={4}
                                onChange={(e:any) => setDescription(e.target.value)}
                                value={description}
                            ></textarea>
                        </div>
                    </Col>
                </Row>

            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant={"secondary"}
                    onClick={handleClose}
                >
                    Cancelar
                </Button>
                <Button
                    variant={"primary"}
                    onClick={handleSubmit}
                >
                    Añadir
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
