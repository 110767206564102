import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Button, Col, Row} from "react-bootstrap";
import {
    ACTIVITY_TYPE_WEEKLY_ID,
    ACTIVITY_TYPE_YEARLY_ID
} from "Components/Activities/ActivityTypeSelect";
import {useMutation} from "react-query";
import {createActivity} from "Api/Mutation/ActivitySession";
import {Notificator} from "Services/Notificator/Notificator";
import {useDispatch} from "react-redux";
import {appLoading} from "Store/loadingSlice";
import {uploadActivityMedia} from "Api/Mutation/ActivityMutation";
import {useNavigate} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {AdminActivityForm} from "Views/Activities/AdminActivityForm";

export const AdminAddActivity: React.FC = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [formData, setFormData] = useState<{ [key: string]: any }>({})
    const [isFormValid, setFormValid] = useState<boolean>(false)

    const uploadMutation = useMutation({
        mutationFn: (body: any) => uploadActivityMedia(body.activityId, body.file, body.tag),
        onMutate: (body: any) => {
            dispatch(appLoading({value: true, text: `Subiendo archivo: ${body.file.file.name} ...`}))
        },
        onError: (error: any) => {
            dispatch(appLoading({value: false}))
            Notificator.error('Ha ocurrido un error al subir la imagen. Inténtalo de nuevo.', 'Error')
        }
    })

    const createActivityMutation = useMutation({
        mutationFn: (body: any) => createActivity(body.activityType, body),
        onSuccess: async (response: any) => {
            const activityId = response.data._result.id

            // Upload frontImage
            await uploadMutation.mutateAsync({
                activityId: activityId,
                file: formData.frontImage,
                tag: 'frontImage'
            })

            // Upload backgroundImage
            if(formData.backgroundImage){
                await uploadMutation.mutateAsync({
                    activityId: activityId,
                    file: formData.backgroundImage,
                    tag: 'backgroundImage'
                })
            }

            Notificator.success('La actividad se ha creado correctamente')
            navigate(ROUTE_PATHS.ADMIN_ACTIVITIES_LIST)
        },
        onError: (error: any) => {
            console.log(error)
            setFormValid(false)
            Notificator.error('Se ha producido un error al crear la actividad')
        }
    })

    const onSubmit = (formData: any, isValid: boolean): void => {
        console.log(formData, isValid)
        if (!isValid) {
            return
        }

        setFormValid(isValid)
        setFormData(formData)
    }

    useEffect(() => {
        if(!isFormValid){
            return
        }

        submit()
    }, [isFormValid])

    useEffect(() => {
        dispatch(appLoading({value: createActivityMutation.isLoading, text: 'Cargando'}))
    }, [createActivityMutation.isLoading])

    const submit = (): void => {

        let body: { [key: string]: any } = {
            name: formData.name,
            centerIds: formData.centerIds,
            description: formData.description,
            startDate: formData.initDate,
            endDate: formData.endDate,
            initPaymentDate: formData.initPaymentDate,
            endPaymentDate: formData.endPaymentDate,
            activityType: formData.type,
            discounts: formData.discountIds
        }

        if (formData.type === ACTIVITY_TYPE_WEEKLY_ID) {
            body = {...body, sessions: []}
        } else if (formData.type === ACTIVITY_TYPE_YEARLY_ID) {
            const yearDays: any[] = []
            formData.yearDays.map(yearDay => {
                let yearDayWithServices = yearDay

                yearDayWithServices.price = parseFloat(yearDayWithServices.price)

                if (yearDayWithServices.start.length === 5) {
                    yearDayWithServices.start = yearDayWithServices.start + ':00'
                }
                if (yearDayWithServices.end.length === 5) {
                    yearDayWithServices.end = yearDayWithServices.end + ':00'
                }

                yearDayWithServices = {...yearDayWithServices, services: formData.services}
                yearDays.push(yearDayWithServices)
            })

            body = {...body, days: yearDays}
        }

        createActivityMutation.mutate(body)
    }

    return (
        <RequireAuth>
            <DefaultContainer>

                {/*{JSON.stringify(formData)}*/}

                <Col id={"mainRequestList"}>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Crear actividad</h1>
                        </Col>
                    </Row>

                    <br/>
                    <AdminActivityForm onSubmit={onSubmit}/>

                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
