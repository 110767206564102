import {Form} from "react-bootstrap";
import Calendar from "react-calendar";
import React, {useEffect, useState} from "react";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {ActivitySession} from "Model/Activity/ActivitySession";
import {MultiValue} from "react-select";
import {SelectOptionType} from "Model/Select/SelectOption";
import {DAY_NAMES_TO_NUMBERS} from "Model/Date/Day";
import {TileDisabledFunc, Value} from "react-calendar/dist/cjs/shared/types";
import {getNextWeekTuesday} from "Services/ActivitySession/ActivitySessionCalendar";

interface Props {
    session: ActivitySession | null,
    selectedDays: MultiValue<SelectOptionType> | null,
    onChange: (value: Value) => void,
    initDate: string,
    endDate: string,
    numberOfDays: number
}

export const InitDateCalendar: React.FC<Props> = (props) => {

    const [activityInitDate, setActivityInitDate] = useState<Date>(new Date(props.initDate))
    const [activityEndDate, setActivityEndDate] = useState<Date>(new Date(props.endDate))
    const [minDate, setMinDate] = useState<Date | null>(null)
    const [value, setValue] = useState<Date|Value|null>(null)
    const [daysArray, setDaysArray] = useState<string[]>([])

    const calcMinDate = () => {
        if (!props.selectedDays) {
            return
        }

        var currentDate = new Date();
        currentDate.setHours(0)
        currentDate.setMinutes(0)
        currentDate.setSeconds(0)
        currentDate.setMilliseconds(0)

        let initDate = activityInitDate

        if(currentDate >= activityInitDate){
            initDate = getNextWeekTuesday(currentDate)
        }

        setMinDate(initDate)

        const daysArray: string[] = []
        props.selectedDays?.map(day => daysArray.push(DAY_NAMES_TO_NUMBERS[day.label.toLowerCase()].toString()))
        setDaysArray(daysArray)
    }

    const isDayDisabled : TileDisabledFunc = ({date}) => {
        return daysArray.indexOf(date.getDay().toString()) === -1
    }

    const onDaySelected = (value: Value) => {
        setValue(value)
        props.onChange(value)
    }

    useEffect(() => {
        calcMinDate()
    }, [props.selectedDays])

    useEffect(() => {
        props.onChange(value)
    }, [value])

    useEffect(() => {
        if(!minDate){
            setValue(null)
            return
        }
    }, [minDate])

    return (
        <>
            <Form.Label>
                Fecha de inicio <FormLabelRequiredAsterisk/>
            </Form.Label>

            {
                minDate &&
                <>
                    <Calendar
                        onChange={(e) => onDaySelected(e)}
                        value={value}
                        locale={"es-ES"}
                        minDate={minDate}
                        maxDate={activityEndDate}
                        defaultActiveStartDate={activityInitDate}
                        tileDisabled={isDayDisabled}
                    />
                </>
            }
        </>
    )
}
